@import "@/assets/scss/style.scss";

.c-checklist-field-index {
    padding: 24px;
    width: 65%;
    margin: 0 auto;

    @media (max-width: 1500px) {
        width: 80%;
    }

    &.has-error {
        border: 1px solid $dull-red;
    }

    &__top-button-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    &__field {
        margin-bottom: 16px;
        //border-bottom: 1px solid $cool-grey;
        padding-bottom: 8px;

        .ant-input {
            &.has-error {
                border: 1px solid $dull-red;
            }
        }

        .ant-radio-wrapper {
            display: block;
            margin-bottom: 12px;

            &.has-error {
                &::v-deep {
                    .ant-radio-inner {
                        border-color: $dull-red;
                    }
                }
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    &__name {
        text-align: center;
        margin-bottom: 24px;
    }

    &__image {
        max-width: 75%;
        margin: 16px auto;
        display: block;
        border-radius: 4px;
        border: 4px solid $cool-grey;

        &-wrapper {
            position: relative;
        }

        &-remove-btn {
            color: red;
            cursor: pointer;
            font-size: 20px;
            padding: 0 5px;
            position: absolute;
            right: 10px;
        }
    }

    &__save-button {
        display: block;
        margin: 0 auto;
    }

    &__preview {
        margin-bottom: 8px;
    }

    &__field-label {
        margin-bottom: 8px;
        color: $black;
    }

    &__dropdown {
        width: 100%;
    }

    &__upload-list-image {
        max-width: 100px;
        margin-right: 16px;
    }

    &__upload-list-remove {
        color: $dull-red;
    }

    &__upload-list-item {
        display: flex;
        position: relative;
        height: 120px;

        &.is-document {
            height: 60px;

            .c-checklist-field-index__upload-list-remove {
                justify-self: center;
                align-self: center;
                margin-left: 16px;
            }
        }

        &.is-image {
            .c-checklist-field-index__upload-list-remove {
                position: absolute;
                right: 0;
                top: 8px;
            }
        }
    }

    &__upload-list-file-icon {
        color: $mid-blue;
        align-self: center;
        margin-right: 16px;
    }

    &__upload-list-field {
        justify-self: center;
        align-self: center;
    }

    &__configurator {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        border-bottom: 1px solid $cool-grey;
    }

    &__configurator-inner {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
            display: flex;
            flex-direction: column;
        }
    }

    &__configurator-delete-button {
        justify-self: center;
        align-self: center;
        font-size: 22px;
        color: $dull-red;
        border: 2px solid #676767;
        padding: 4px;
        border-radius: 50%;
        transition: opacity 500ms ease;
        cursor: pointer;
        margin-left: 8px;

        &:hover {
            opacity: 0.7;
        }
    }

    &__configurator-title {
        margin-bottom: 8px;
    }

    &__configurator-field {
        margin-bottom: 16px;
    }

    &__configurator-button {
        display: block;
        margin-top: 16px;
        margin-left: auto;
        margin-right: 0;
    }

    &__delete {
        display: block;
        margin-right: 0;
        margin-left: 24px;
        margin-bottom: 24px;
    }

    &__segment {
        background-color: $white-two;
        margin-bottom: 12px;
        padding: 16px;
        border-radius: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__segment-title {
        margin-bottom: 30px;
        flex: 1 0 auto;
    }

    &__segment-title-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }
}
.c-cms-index-save-button {
    width: calc(65% - 48px);
    display: block;
    margin: 0 auto !important;
}
.c-cms-index-delete-button {
    width: calc(65% - 48px);
    display: block;
    margin: 24px auto !important;
}
